import { reactive } from 'vue';
import { API } from '@/api/core';
import { Enum } from '@/core/utils/enum';
import moment from 'moment';
import { Project } from './project';
import { Invoice, Transaction } from './order';

export const ProjectGroup = new Enum({
  RoanuzDevelopers: 'roanuz_developers_project',
  RoanuzInternals: 'roanuz_internal_service_project',
  Trail: 'customer_trail_project',
  Live: 'live_project',
  Test: 'test_project',
});
export const InvoiceGroup = new Enum({
  RoanuzDevelopers: 'roanuz_developers_project',
  RoanuzInternals: 'roanuz_internal_service_project',
  Trail: 'customer_trail_project',
  Live: 'live_project',
  Test: 'test_project',
  PurchaseInvoice: 'purchase',
  BillingInvoice: 'billing',
  ApprovedInvoice: 'approved_invoice',
});

export const PaymentStatus = new Enum({
  WaitingForPayment: 'waiting_for_payment',
  PaymentSuccess: 'payment_transaction_success',
  PaymentFailed: 'payment_transaction_failed',
  PaymentNotRequired: 'payment_not_required',
  PaymentNotRefunded: 'payment_refunded',
});

export const ProjectStatusGroup = new Enum({
  Active: 'active',
  InactiveByPaymentPending: 'inactive_by_payment_pending',
  InactiveByTrailEnd: 'inactive_by_trail_end',
  Suspended: 'suspended',
});

export const DataScope = new Enum({
  liveSports: 'live_sports',
  virtualSports: 'virtual_sports',
});

export const TransactionFor = new Enum({
  FreeCredit: {
    value: 'free_credit',
    label: 'Free Credit',
  },
  PromoCredit: {
    value: 'promo_credit',
    label: 'Promo Credit',
  },
  RefundCredit: {
    value: 'refund_credit',
    label: 'refund Credit',
  },
  V2MigrationCredit: {
    value: 'v2migration_credit',
    label: 'v2migration Credit',
  },
});

export const PackageTypes = new Enum({
  AssociationPackage: {
    key: 'association_package',
    name: 'Association Package',
  },
  TournamentPackage: {
    key: 'tournament_package',
    name: 'Tournament Package',
  },
  StandardPackage: {
    key: 'standard_package',
    name: 'Standard Package',
  },
  FreePlan: {
    key: 'free_plan',
    name: 'Free Plan',
  },
  AddonHits: {
    key: 'addon_hits',
    name: 'Addon Hits',
  },
  AddonMatches: {
    key: 'addon_matches',
    name: 'Addon Matches',
  },
});

export const PackageLimitTypes = new Enum({
  Month: {
    key: 'Month',
    name: 'Month',
  },
  NoLimit: {
    key: 'NA',
    name: 'No Limit',
  },
});

export const SalesProcessStep = new Enum({
  0: {
    value: '0',
    label: 'Unknown',
  },
  101: {
    value: '101',
    label: 'GettingStarted',
  },
  102: {
    value: '102',
    label: 'WaitingForGettingStarted',
  },
  201: {
    value: '201',
    label: 'Lead',
  },
  301: {
    value: '301',
    label: 'Exploring',
  },
  401: {
    value: '401',
    label: 'NewFeatureRequest',
  },
  1001: {
    value: '1001',
    label: 'Unqualified',
  },
  2001: {
    value: '2001',
    label: 'Unhappy',
  },
  3001: {
    value: '3001',
    label: 'Completed',
  },
});

export const PaymentMedium = new Enum({
  Card: 'card',
  Gateway: 'gateway',
  Invoice: 'invoice',
});

export const PaymentGateway = new Enum({
  Stripe: 'stripe',
  Razorpay: 'razorpay',
  Paypal: 'paypal',
});

export const IgnoredProjectGroups = [
  ProjectGroup.RoanuzDevelopers,
  ProjectGroup.RoanuzInternals,
  ProjectGroup.Test,
  ProjectGroup.Trail,
];
export const IgnoredInvoiceGroups = [
  ProjectGroup.RoanuzDevelopers,
  ProjectGroup.RoanuzInternals,
  ProjectGroup.Test,
  ProjectGroup.Trail,
];

export const DefaultProjectGroupsOrder = [
  ProjectGroup.Live,
  ProjectGroup.Trail,
  ProjectGroup.Test,
  ProjectGroup.RoanuzDevelopers,
  ProjectGroup.RoanuzInternals,
];
export const DefaultInvoiceGroupsOrder = [
  InvoiceGroup.Live,
  InvoiceGroup.Trail,
  InvoiceGroup.Test,
  InvoiceGroup.RoanuzDevelopers,
  InvoiceGroup.RoanuzInternals,
  InvoiceGroup.PurchaseInvoice,
  InvoiceGroup.BillingInvoice,
  InvoiceGroup.ApprovedInvoice,
];

export const BillGroup = new Enum({
  PayByInvoice: 'pay_by_invoice',
  Prepay: 'prepay',
  Unknown: 'unknown',
});

export class ProjectList {
  static fetch() {
    const params = { };
    return API.instance()
      .get('/admin/business/projects/', { params })
      .then((resp) => {
        const { projects } = resp.data.data;
        const projectList = [];
        projects.forEach((project) => {
          projectList.push(Project.createFromJson(project));
        });
        return projectList;
      });
  }

  static fetchDiscounts(params) {
    return API.instance()
      .get('/business/cricket/lifetime_discounts/', { params })
      .then((resp) => {
        const discounts = resp.data.data;
        return discounts;
      });
  }

  static addDiscounts(params) {
    if (params.action === 'Add Discounts') {
      return API.instance()
        .post('admin/business/other/actions/add-lifetime-discount/', {
          start_date: params.start_date,
          end_date: params.end_date,
          discount_minimum_amount: params.discount_minimum_amount,
          discount_percentage: params.discount_percentage,
          slug_key: params.slug_key,
          active: true,
        })
        .then((resp) => {
          if (resp.data.data) {
            return resp.data.data;
          }
          return resp.data.error;
        });
    }
    return API.instance()
      .post('admin/business/other/actions/add-lifetime-discount/', {
        start_date: params.start_date,
        end_date: params.end_date,
        discount_minimum_amount: params.discount_minimum_amount,
        discount_percentage: params.discount_percentage,
        slug_key: params.slug_key,
        active: true,
        key: params.key.value,
      })
      .then((resp) => {
        if (resp.data.data) {
          return resp.data.data;
        }
        return resp.data.error;
      });
  }

  static getPackagesList(params) {
    return API.instance()
      .get(`/business/project/${params.key}/packages_list/`)
      .then((resp) => resp);
  }

  static extendPlan(params) {
    return API.instance()
      .post('admin/business/extend/package/', {
        project: params.project,
        product: params.product,
        validity: params.validity,
        plan_start_date: params.plan_start_date,
        private_notes: params.private_notes,
        customer_notes: params.customer_notes,
      })
      .then((resp) => {
        if (resp.data.data) {
          return resp.data.data;
        }
        return resp.data.error;
      });
  }

  static attachTrial(params) {
    return API.instance()
      .post('admin/business/attach_free_trial/', {
        project: params.project,
        product: params.product,
        validity: params.validity,
        plan_start_date: params.plan_start_date,
        private_notes: params.private_notes,
        customer_notes: params.customer_notes,
      })
      .then((resp) => {
        if (resp.data.data) {
          return resp.data.data;
        }
        return resp.data.error;
      });
  }

  static fetchCouponInfo(params) {
    return API.instance()
      .get(`admin/business/coupons/list/?coupon_status=${
        params.active ? 'active' : 'inactive'}&page=${params.page}&limit=10`)
      .then((resp) => {
        const coupons = resp.data.data;
        return coupons;
      });
  }

  static invalidCoupon(key) {
    return API.instance()
      .post(`admin/business/coupons/invalidate/${key}/`, {})
      .then((resp) => resp);
  }

  static addCoupon(params) {
    return API.instance()
      .post('admin/business/coupons/create/', {
        name: params.name,
        discount_percentage: params.discount_percentage,
        applicable_product: params.applicable_product,
        applicable_product_type: params.applicable_product_type,
        notes: params.notes,
        expiry_date: params.expiry_date,
        project_key: params.project_key,
        quantity: params.quantity,
      })
      .then((resp) => {
        if (resp.data.data) {
          return resp.data.data;
        }
        return resp.data.error;
      });
  }

  static fetchPackageInfo(params) {
    return API.instance()
      .get(`admin/business/products/package_product/all/?show_all=0&show_active=${
        params.active ? '1' : '0'}`)
      .then((resp) => {
        const packages = resp.data.data;
        return packages;
      });
  }

  static addPackages(params) {
    return API.instance()
      .post('admin/business/products/package_product/create/', {
        resource_key: params.resource_key,
        name: params.name,
        description: params.description,
        subtitle: params.subtitle,
        price: params.price,
        show_early_bird_pricing: params.show_early_bird_pricing || false,
        show_in_website: params.show_in_website,
        show_resource_page: params.show_resource_page,
        allowed_endpoints: params.allowed_endpoints,
        index: params.index,
        is_ants_product: params.is_ants_product,
        early_bird_pricing_notes: params.early_bird_pricing_notes,
        active: params.active,
        type: params.type,
        days_of_access: params.type === 'association_package'
          ? (params.days_of_access || 30.41) : params.days_of_access,
        key: params.key,
        rights_metadata: params.rights_metadata,
      })
      .then((resp) => {
        if (resp.data.data) {
          return resp.data.data;
        }
        return resp.data.error;
      });
  }

  static editPackage(params) {
    return API.instance()
      .put(`admin/business/products/package_product/${params.key}/`, {
        resource_key: params.resource_key,
        name: params.name,
        description: params.description,
        subtitle: params.subtitle,
        price: params.price,
        show_early_bird_pricing: params.show_early_bird_pricing || false,
        show_in_website: params.show_in_website,
        show_resource_page: params.show_resource_page,
        allowed_endpoints: params.allowed_endpoints,
        active: params.active,
        index: params.index,
        is_ants_product: params.is_ants_product,
        early_bird_pricing_notes: params.early_bird_pricing_notes,
        type: params.type,
        days_of_access: params.type === 'association_package'
          ? (params.days_of_access || 30.41) : params.days_of_access,
        key: params.key,
        rights_metadata: params.rights_metadata,
      })
      .then((resp) => {
        if (resp.data.data) {
          return resp.data.data;
        }
        return resp.data.error;
      });
  }

  static filterQuery(filters) {
    const params = filters || { limit: 50 };

    return API.instance()
      .get('/admin/business/projects/', { params })
      .then((resp) => {
        const { projects } = resp.data.data;
        const page = resp.data.data.pagination_params;
        const projectList = [];
        projects.forEach((project) => {
          projectList.push(Project.createFromJson(project));
        });

        return { projects: projectList, page };
      });
  }

  static invoiceList(year, month) {
    return API.instance()
      .get(`/admin/business/invoices/${year}/${month}/`)
      .then((resp) => {
        const projects = resp.data;
        return projects;
      });
  }

  static transactionList(month, year) {
    return API.instance()
      .get(`/admin/business/transactions/${month}/${year}/`)
      .then((resp) => {
        const projects = resp.data.data;
        return projects;
      });
  }

  // Delete this
  static fetchProjectInfo(key) {
    return API.instance()
      .get(`admin/business/project/view/${key}/`)
      .then((resp) => {
        const projects = resp.data.data;
        return projects;
      });
  }

  static fetchProject(key) {
    return API.instance()
      .get(`admin/business/project/view/${key}/`)
      .then((resp) => Project.createFromJson(resp.data.data));
  }

  static activateFreeTrail(key, days) {
    return API.instance()
      .post(`admin/business/project/actions/${key}/activate-free-trail/`, {
        project_key: key,
        trail_period_days: parseInt(days, 10),
      })
      .then((resp) => Project.createFromJson(resp.data.data));
  }

  static deactivateFreeTrail(key) {
    return API.instance()
      .post(`admin/business/project/actions/${key}/deactivate-free-trail/`, {
        project_key: key,
      })
      .then((resp) => Project.createFromJson(resp.data.data));
  }

  static verifyBilling(key) {
    return API.instance()
      .post(`admin/business/project/actions/${key}/verify-billing/`, {
        project_key: key,
      })
      .then((resp) => Project.createFromJson(resp.data.data));
  }

  static deleteProject(key, val) {
    if (val) {
      return API.instance()
        .post(`admin/business/project/actions/${key}/delete-project/`, {
          project_key: key,
          mark_as_test: true,
        })
        .then((resp) => Project.createFromJson(resp.data.data));
    }
    return API.instance()
      .post(`admin/business/project/actions/${key}/delete-project/`, {
        project_key: key,
      })
      .then((resp) => Project.createFromJson(resp.data.data));
  }

  static unverifyBilling(key) {
    return API.instance()
      .post(`admin/business/project/actions/${key}/unverify-billing/`, {
        project_key: key,
      })
      .then((resp) => Project.createFromJson(resp.data.data));
  }

  static changeProjectGroups(key, groupName) {
    return API.instance()
      .post(`admin/business/project/actions/${key}/edit-project-group/`, {
        project_key: key,
        group: groupName,
      })
      .then((resp) => Project.createFromJson(resp.data.data));
  }

  static changeProjectStatusGroups(key, status) {
    return API.instance()
      .post(`/admin/business/project/actions/${key}/set-project-status/`, {
        project_key: key,
        status,
      })
      .then((resp) => Project.createFromJson(resp.data.data));
  }

  static changePendingStatusGroups(key, pendingStatus) {
    return API.instance()
      .post(`/admin/business/project/actions/${key}/set-project-pending-status/`, {
        project_key: key,
        pending_status: pendingStatus,
      })
      .then((resp) => Project.createFromJson(resp.data.data));
  }

  static changeDataScopeGroups(key, dataScope) {
    return API.instance()
      .post(`/admin/business/project/actions/${key}/set-project-data-scope/`, {
        project_key: key,
        data_scope: dataScope,
      })
      .then((resp) => Project.createFromJson(resp.data.data));
  }

  static changeSalesProcessStep(key, step) {
    return API.instance()
      .post(`/admin/business/project/actions/${key}/edit-sales-process-step/`, {
        project_key: key,
        sales_process_step: parseInt(step, 10),
      })
      .then((resp) => Project.createFromJson(resp.data.data));
  }

  static addNotes(key, payload) {
    return API.instance()
      .post(`admin/business/project/actions/${key}/add-notes/`, payload)
      .then((resp) => Project.createFromJson(resp.data.data));
  }

  static addPrivateNotes(key, payload) {
    return API.instance()
      .post(`admin/business/project/actions/${key}/add-private-notes/`, payload)
      .then((resp) => Project.createFromJson(resp.data.data));
  }

  static addSalesProcessNotes(key, payload) {
    return API.instance()
      .post(`admin/business/project/actions/${key}/edit-sales-process-notes/`, payload)
      .then((resp) => Project.createFromJson(resp.data.data));
  }

  static editProjectLimit(key, limit) {
    return API.instance()
      .post(`/admin/business/project/actions/${key}/edit-project-billing-limit/`, {
        project_key: key,
        custom_billing_limit: limit,
      })
      .then((resp) => Project.createFromJson(resp.data.data));
  }

  static changeBillingMethod(key, method) {
    return API.instance()
      .post(`admin/business/project/actions/${key}/edit-billing-method/`, {
        project_key: key,
        billing_method: method,
      })
      .then((resp) => Project.createFromJson(resp.data.data));
  }

  static addFreeCredits(key, amount, notes) {
    return API.instance()
      .post(`/admin/business/project/actions/${key}/add-free-credits/`, {
        project_key: key,
        amount,
        notes,
      })
      .then((resp) => Project.createFromJson(resp.data.data));
  }

  static extendAccessTermination(key, date) {
    return API.instance()
      .post(`/admin/business/project/actions/${key}/set-disable-api-access-termination/`, {
        project_key: key,
        disable_api_access_termination: true,
        disable_api_access_termination_until: date,
      })
      .then((resp) => Project.createFromJson(resp.data.data));
  }

  static addLifetimeDiscount(key, percentage, amount) {
    return API.instance()
      .post(`/admin/business/project/actions/${key}/attach-lifetime-discount/`, {
        project_key: key,
        discount_minimum_amount: amount,
        discount_percentage: percentage,
      })
      .then((resp) => Project.createFromJson(resp.data.data));
  }

  static searchQuery(query, docType) {
    return API.instance()
      .get(`/admin/business/search/${docType}/?query=${query}`)
      .then((resp) => resp);
  }

  static salesReport(type, month, year) {
    return API.instance()
      .get(`/admin/business/sales-report/${type}/?year=${year}&month=${month}`)
      .then((resp) => resp);
  }

  static activityReport(key, params) {
    if (params) {
      return API.instance()
        .get(`/admin/business/project/${key}/activity/?limit=20&last=${params.last}`)
        .then((resp) => resp);
    }
    return API.instance()
      .get(`/admin/business/project/${key}/activity/?limit=20`)
      .then((resp) => resp);
  }

  static projectSalesProcessReport(params) {
    if (params && params.total_projects) {
      return API.instance()
        .get('/admin/business/project-report/sales-process-report/'
        + `?limit=20&page=${params.page}&sales_process_step=${params.step}&total_projects=${params.total_projects}`)
        .then((resp) => resp);
    }
    return API.instance()
      .get('/admin/business/project-report/sales-process-report/'
        + `?limit=20&page=${params.page}&sales_process_step=${params.step}`)
      .then((resp) => resp);
  }

  static customerSalesProcessReport(params) {
    if (params && params.total_customers) {
      return API.instance()
        .get('/admin/business/customer-report/sales-process-report/'
        + `?limit=20&page=${params.page}&sales_process_step=${params.step}&total_customers=${params.total_customers}`)
        .then((resp) => resp);
    }
    return API.instance()
      .get('/admin/business/customer-report/sales-process-report/'
        + `?limit=20&page=${params.page}&sales_process_step=${params.step}`)
      .then((resp) => resp);
  }
}

const CONVERSTION_RATE = {
  eur: 1,
  usd: 0.84,
  inr: 0.013,
};

export class UsageReportItem {
  static createFromJson(raw) {
    let data = new UsageReportItem();
    data = Object.assign(data, raw);
    return data;
  }
}

export class UsageReport {
  constructor(items) {
    this.items = items;
    this.convertionRate = CONVERSTION_RATE;
    this.totalByCurrency = {};
    this.total = {};
    this.buildReport();
  }

  initReport() {
    this.totalByCurrency = {
      eur: this.currencyObject(),
      usd: this.currencyObject(),
      inr: this.currencyObject(),
    };

    this.total = this.currencyObject();
  }

  buildReport() {
    this.initReport();
    this.items.forEach((item) => {
      if (item.cost <= 0.0) {
        item.eur = item.cost;
        return;
      }

      const total = this.totalByCurrency[item.currency];
      const eur = item.cost * this.convertionRate[item.currency];
      item.eur = eur;
      total.amount += item.cost;
      total.eur += eur;
      total.projects += 1;

      this.total.amount += eur;
      this.total.eur = this.total.amount;
      this.total.projects += 1;
    });

    this.items.sort((x, y) => y.eur - x.eur);
  }

  // eslint-disable-next-line class-methods-use-this
  currencyObject() {
    return { amount: 0, eur: 0, projects: 0 };
  }

  static fetch(date) {
    const year = date.year();
    const month = date.format('MM');
    const params = { };
    return API.instance()
      .get(`admin/business/usages/${year}/${month}/`, { params })
      .then((resp) => {
        const usage = resp.data.data.monthly_usages.map((x) => UsageReportItem.createFromJson(x));
        return new UsageReport(usage);
      });
  }
}

export class TransactionItem {
  static createFromJson(raw) {
    let data = new TransactionItem();
    data = Object.assign(data, raw);
    if (raw.payment_status) {
      data.payment_status = PaymentStatus.parse(raw.payment_status);
    }
    return data;
  }
}
export class Report {
  constructor(items) {
    this.items = items;
    this.convertionRate = CONVERSTION_RATE;
    this.totalByCurrency = {};
    this.total = {};
    this.buildReport();
  }

  initReport() {
    this.totalByCurrency = {
      eur: this.currencyObject(),
      usd: this.currencyObject(),
      inr: this.currencyObject(),
    };

    this.total = this.currencyObject();
  }

  buildReport() {
    this.initReport();
    this.items.forEach((item) => {
      if (item.amount <= 0.0) {
        item.eur = item.amount;
        return;
      }

      const total = this.totalByCurrency[item.currency];
      const eur = item.amount * this.convertionRate[item.currency];
      item.eur = eur;
      total.amount += item.amount;
      total.eur += eur;
      total.transactions += 1;

      this.total.amount += eur;
      this.total.eur = this.total.amount;
      this.total.transactions += 1;
    });

    this.items.sort((x, y) => y.eur - x.eur);
  }

  // eslint-disable-next-line class-methods-use-this
  currencyObject() {
    return { amount: 0, eur: 0, transactions: 0 };
  }
}
export class TransactionReport {
  constructor(items) {
    this.items = new Report(items);
    this.unpaid = [];
    this.paid = [];
    this.unpaidItems(items);
    this.paidItems(items);
  }

  unpaidItems(items) {
    items.forEach((item) => {
      if (item.payment_status.isPaymentFailed
        || item.payment_status.isWaitingForPayment) {
        this.unpaid.push(item);
      }
    });
    this.unpaid = new Report(this.unpaid);
  }

  paidItems(items) {
    items.forEach((item) => {
      if (item.payment_status.isPaymentSuccess
        || (item.payment_status.isPaymentNotRequired && item.kind === 'tds')) {
        this.paid.push(item);
      }
    });
    this.paid = new Report(this.paid);
  }

  static fetchTransaction(transactionKey) {
    return API.instance()
      .get(`business/transaction/${transactionKey}/`)
      .then((resp) => Transaction.createFromJson(resp.data.data));
  }

  static fetch(year, month) {
    const params = { };
    return API.instance()
      .get(`/admin/business/transactions/${year}/${month}/`, { params })
      .then((resp) => {
        const transaction = resp.data.data.transactions.map((x) => TransactionItem.createFromJson(x));
        return new TransactionReport(transaction);
      });
  }
}

export class InvoiceReportItem {
  static createFromJson(raw) {
    let data = new InvoiceReportItem();
    data = Object.assign(data, raw);
    return data;
  }
}

export class InvoiceReport {
  constructor(items, showFullfilled) {
    if (showFullfilled !== undefined) {
      this.items = items.filter((x) => x.is_fulfilled === showFullfilled);
    } else {
      this.items = [...items];
    }
    this.items.sort((x, y) => y.generated_date - x.generated_date);
    this.convertionRate = CONVERSTION_RATE;
    this.totalByCurrency = {};
    this.total = {};
    this.buildReport();
  }

  initReport() {
    this.totalByCurrency = {
      eur: this.currencyObject(),
      usd: this.currencyObject(),
      inr: this.currencyObject(),
    };

    this.total = this.currencyObject();
  }

  buildReport(items) {
    this.initReport();
    const filteredItems = items || this.items;
    filteredItems.forEach((item) => {
      if (item.grand_total <= 0.0) {
        return;
      }

      const total = this.totalByCurrency[item.currency];
      const eur = item.grand_total * this.convertionRate[item.currency];
      item.eur = eur;
      total.amount += item.grand_total;
      total.eur += eur;
      total.invoices += 1;

      this.total.amount += eur;
      this.total.eur = this.total.amount;
      this.total.invoices += 1;
    });
  }

  // eslint-disable-next-line class-methods-use-this
  currencyObject() {
    return { amount: 0, eur: 0, invoices: 0 };
  }

  static fetch(year, month) {
    const params = { };
    return API.instance()
      .get(`admin/business/invoices/${year}/${month}/`, { params })
      .then((resp) => {
        const usage = resp.data.data.invoices.map((x) => InvoiceReportItem.createFromJson(x));
        const cancelledInvoices = resp.data.data.cancelled_invoices.map((x) => InvoiceReportItem.createFromJson(x));
        const approvedInvoices = resp.data.data.invoices.filter((x) => x.is_verified === true);
        const fulfilled = new InvoiceReport(usage, true);
        const notFulfilled = new InvoiceReport(usage, false);
        const cancelled = new InvoiceReport(cancelledInvoices);
        const approved = new InvoiceReport(approvedInvoices);
        return {
          fulfilled, notFulfilled, cancelled, approved,
        };
      });
  }

  static fetchTotalReport(year) {
    const params = {};
    return API.instance()
      .get(`admin/business/invoice_yearly_summary/${year}/`, { params })
      .then((resp) => Invoice.createFromJson(resp.data.data));
  }

  static fetchInvoice(invoiceKey) {
    return API.instance()
      .get(`business/invoice/${invoiceKey}/`)
      .then((resp) => Invoice.createFromJson(resp.data.data));
  }

  static fetchAdminInvoice(invoiceKey) {
    return API.instance()
      .get(`admin/business/invoice/${invoiceKey}/`)
      .then((resp) => Invoice.createFromJson(resp.data.data));
  }

  static action(invoiceKey, payload) {
    return API.instance()
      .post(`admin/business/invoice/${invoiceKey}/actions/`, payload)
      .then((resp) => Invoice.createFromJson(resp.data.data));
  }

  static addTransaction(payload) {
    return API.instance()
      .post('admin/business/transaction/create/', payload)
      .then((resp) => Invoice.createFromJson(resp.data.data));
  }

  static attachTransaction(key, payload) {
    return API.instance()
      .post(`admin/business/transaction/${key}/actions/`, payload)
      .then((resp) => resp);
  }
}

export class UserInvoiceReport {
  constructor(items, showFullfilled, cancelled, approved) {
    if (showFullfilled) {
      this.items = items.filter((x) => x.is_fulfilled && x.status !== 'cancelled_invoice');
    } else if (cancelled) {
      this.items = items.filter((x) => x.status === 'cancelled_invoice');
    } else if (approved) {
      this.items = items.filter((x) => x.is_verified === true);
    } else {
      this.items = items.filter((x) => !x.is_fulfilled
       && x.status !== 'cancelled_invoice');
    }
    this.items.sort((x, y) => y.generated_date - x.generated_date);
    this.convertionRate = CONVERSTION_RATE;
    this.totalByCurrency = {};
    this.total = {};
    this.buildReport();
  }

  initReport() {
    this.totalByCurrency = {
      eur: this.currencyObject(),
      usd: this.currencyObject(),
      inr: this.currencyObject(),
    };

    this.total = this.currencyObject();
  }

  buildReport(items) {
    this.initReport();
    const filteredItems = items || this.items;
    filteredItems.forEach((item) => {
      if (item.grand_total <= 0.0) {
        return;
      }

      const total = this.totalByCurrency[item.currency];
      const eur = item.grand_total * this.convertionRate[item.currency];
      item.eur = eur;
      total.amount += item.grand_total;
      total.eur += eur;
      total.invoices += 1;

      this.total.amount += eur;
      this.total.eur = this.total.amount;
      this.total.invoices += 1;
    });
  }

  // eslint-disable-next-line class-methods-use-this
  currencyObject() {
    return { amount: 0, eur: 0, invoices: 0 };
  }
}

export class Users {
  static createFromJson(raw) {
    let data = new Users();
    data = Object.assign(data, raw);
    return data;
  }
}

export class UsersReport {
  constructor(items) {
    this.items = items;
  }

  static fetch() {
    return API.instance()
      .get('admin/business/customers/')
      .then((resp) => Users.createFromJson(resp.data.data));
  }

  static filterQuery(filters) {
    const params = filters || { limit: 50 };

    return API.instance()
      .get('/admin/business/customers/', { params })
      .then((resp) => {
        const customerList = resp.data.data.customers;
        const page = resp.data.data.pagination_params;
        const customers = [];
        customerList.forEach((customer) => {
          customers.push(Users.createFromJson(customer));
        });
        return { customers, page };
      });
  }

  static fetchProjects(details) {
    return API.instance()
      .get(`admin/business/customer/${details}/projects/`)
      .then((resp) => Users.createFromJson(resp.data.data));
  }

  static filterProjectsQuery(detail, filters) {
    const params = filters || { limit: 50 };

    return API.instance()
      .get(`admin/business/customer/${detail.user}/projects/`, { params })
      .then((resp) => {
        const customerList = resp.data.data.projects;
        const page = resp.data.data.pagination_params;
        const projects = [];
        customerList.forEach((project) => {
          projects.push(Users.createFromJson(project));
        });
        return { projects, page };
      });
  }

  static fetchInvoice(detail) {
    return API.instance()
      .get(`admin/business/customer/${detail}/invoices/`)
      .then((resp) => {
        const usage = resp.data.data.invoices.map((x) => Users.createFromJson(x));
        const fulfilled = new UserInvoiceReport(usage, true, false, false);
        const notFulfilled = new UserInvoiceReport(usage, false, false, false);
        const cancelled = new UserInvoiceReport(usage, false, true, false);
        const approved = new UserInvoiceReport(usage, false, false, true);
        return {
          fulfilled, notFulfilled, cancelled, approved,
        };
      });
  }

  static fetchCustomerData(key, filters) {
    const params = filters || { limit: 50 };
    return API.instance()
      .get(`admin/business/customer/${key}/user-info/`, { params })
      .then((resp) => Users.createFromJson(resp.data.data));
  }

  static fetchInvoiceData(invoiceKey) {
    return API.instance()
      .get(`business/invoice/${invoiceKey}/`)
      .then((resp) => Users.createFromJson(resp.data.data));
  }

  static changeSalesProcessStep(key, step) {
    return API.instance()
      .post(`/admin/business/user/actions/${key}/edit-sales-process-step/`, {
        customer_key: key,
        sales_process_step: parseInt(step, 10),
      })
      .then((resp) => Users.createFromJson(resp.data.data));
  }

  static addSalesProcessNotes(key, payload) {
    return API.instance()
      .post(`admin/business/user/actions/${key}/edit-sales-process-notes/`, payload)
      .then((resp) => Users.createFromJson(resp.data.data));
  }
}

export class AdminAction {
  constructor(fn, onDone = null, {
    ask = false,
    context = null,
    model = '',
    notes = '',
    privateNotes = '',
  } = {}) {
    this.fn = fn;
    this.onDone = onDone || null;
    this.ask = ask;
    this.context = context;
    this.model = model;
    this.notes = notes;
    this.privateNotes = privateNotes;
    this.amount = '';
    this.visibleToCustomer = true;
    this.transactionDate = moment().format('DD/MM/YYYY');
    this.tdsTrackingVerified = true;
    this.transactionFor = TransactionFor.FreeCredit;
    this.isWalletTransaction = true;
    this.paymentGateway = PaymentGateway.Card;
    this.paymentMedium = PaymentMedium.Stripe;
    this.referenceNumber = '';
    this.startDate = '';
    this.endDate = '';
    this.minAmountUsd = null;
    this.minAmountEur = null;
    this.minAmountInr = null;
    this.percentage = '';
    this.slugkey = '';
  }

  askConfirm() {
    this.ask = true;
  }

  cancel() {
    this.cancelAsk();
  }

  cancelAsk() {
    this.ask = false;
  }
}

const SeperatorItemKey = '-';

export class FilterableItem {
  constructor({
    name,
    isCheckbox = false,
    key = null,
    number = null,
    selected = false,
    route = null,
    item = null,
  }) {
    this.name = name;
    this.key = key || name;
    this.number = number;
    this.isCheckbox = isCheckbox;
    this.selected = selected;
    this.route = route;
    this.item = item;
  }

  static SeperatorItem() {
    return new FilterableItem({
      name: SeperatorItemKey,
      isCheckbox: false,
    });
  }
}

export class Filterables {
  constructor({ items, activeItem = null }) {
    this.items = items;
    this.activeItem = activeItem;
  }
}

const initState = () => reactive({
  previewProject: null,
  previewInvoice: null,
  previewTransaction: null,
  previewAdminInvoice: null,
  previewUser: null,
  previewUsersInvoice: null,
  previewUsersProject: null,
  previewUserDetails: null,
});
const getters = {};
const actions = {};
const mutations = {

  updatePreviewProject(state, { project }) {
    state.previewProject = project;
  },

  updatePreviewInvoice(state, { invoice }) {
    state.previewInvoice = invoice;
  },

  updatePreviewTransaction(state, { transaction }) {
    state.previewTransaction = transaction;
  },

  updatePreviewAdminInvoice(state, { adminInvoice }) {
    state.previewAdminInvoice = adminInvoice;
  },

  updatePreviewUser(state, { user }) {
    state.previewUser = user;
  },

  updatePreviewUsersInvoice(state, { usersInvoice }) {
    state.previewUsersInvoice = usersInvoice;
  },
  updatePreviewUsersProject(state, { userProject }) {
    state.previewUsersProject = userProject;
  },

  updateUserDetails(state, { details }) {
    state.previewUserDetails = details;
  },

};

export default {
  namespaced: true,
  state: initState,
  getters,
  actions,
  mutations,
};
